import React from 'react';
import Layout from "../components/Layout/Layout";
import TagItem from '../components/Main/MainItem/MainItem';
//mport AsideRight from "../components/Asides/Aside_right/Aside_right";
import ADS from "../components/Asides/ADS/Ads_left";
import styles from './Tag.module.scss';
import SEO from '../components/seo';

const Tag = ({ pageContext: {data, title} }) => {
  const url = encodeURI(`https://stasonmars.ru/tag/${title}`);

  return(
    <Layout>
      <SEO title={`Посты с тегом "${title}"`} description={`На этой странице вы увдитие статьи, помеченные тегом "${title}". Тут есть полезные советы, интересная информация и полезные материалы по этой теме.`} keywords={title} img_smm={'https://stasonmars.ru/tag_ing.jpg'} url={url} />
      <ADS />
      <main className={styles.tag__container}>
        <header className={styles.tag__container__header}>
          <img src="/../tag.png" alt={title} />
          <h1 className={styles.tag__container__title}>#{title}</h1>
        </header>
        <section>
          {data.map((item, i) => <TagItem key={i+3489} postData={item} />)}
        </section>
      </main>

    </Layout>
  )
};

export default Tag;