import React from 'react';
import styles from './Layout.module.scss';
import './global.css';
import Header from './Header/Header';
import Footer from './Footer/Footer';

const Layout = ({children}) => (
    <div className={styles.grids}>
        <Header />
            {children}
        <Footer />
    </div>
);

export default Layout;