import React from 'react';
import styles from './MainItem.module.scss';
import { Link } from 'gatsby';

const MainItem = ({ postData }) => {
    let type = postData.type;
    if(postData.type === 'js') {
        type = 'javascript';
    }
    
    return (
        <article className={styles.main__item}>
            <header>
                <Link to={`/${type}/${postData.slug}/`}><h2 className={styles.headerPost}>{postData.title}</h2></Link>
            </header>
            <section>
                <Link to={`/${type}/${postData.slug}/`}><div className={styles.excerpt} dangerouslySetInnerHTML={{__html: postData.excerpt}} aria-label={postData.title} /></Link>
            </section>     
        </article>
    )
}

export default MainItem;